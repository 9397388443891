import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: {
      keepalive: false
    }
  },
  {
    path: '/Staff',
    name: 'Staff',
    component: () => import('../views/Staff.vue'),
    meta: {
      keepalive: true
    }
  },
  {
    path: '/Grading',
    name: 'Grading',
    component: () => import('../views/Grading.vue'),
    meta: {
      keepalive: true
    }
  },
  {
    path: '/ProfessionalLevel',
    name: 'ProfessionalLevel',
    component: () => import('../views/ProfessionalLevel.vue'),
    meta: {
      keepalive: true
    }
  },
  {
    path: '/ProjectType',
    name: 'ProjectType',
    component: () => import('../views/ProjectType.vue'),
    meta: {
      keepalive: true
    }
  },
  {
    path: '/ProjectList',
    name: 'ProjectList',
    component: () => import('../views/ProjectList.vue'),
    meta: {
      keepalive: true
    }
  },
  {
    path: '/Wage',
    name: 'Wage',
    component: () => import('../views/Wage.vue'),
    meta: {
      keepalive: true
    }
  },
  {
    path: '/Information',
    name: 'Information',
    component: () => import('../views/Information.vue'),
    meta: {
      keepalive: true
    }
  },
  {
    path: '/Company',
    name: 'Company',
    component: () => import('../views/Company.vue'),
    meta: {
      keepalive: true
    }
  }
]
const hash = createWebHashHistory();
const router = createRouter({
  history: hash,
  routes
})

export default router

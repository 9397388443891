import { createStore } from 'vuex'

export default createStore({
  state: {
    header: {},
    // 接口路径
    base_url: "https://log.toctec.cn/rbxt/api/v1.0/",
		ws: "wss://log.toctec.cn/websocket/", 
    /*base_url: "http://192.168.12.107:8780/ys-rbxt/rbxt/api/v1.0/",
    ws: "ws://192.168.12.107:8780/ys-rbxt/websocket/",*/
    // UrlPath: 'http://192.168.12.107:8780/ys-rbxt/rbxt/api/v1.0/ManageUsers',
    // Dialog的
    dislogVisible: false,
    // DialogT的
    dislogVisibleT: false,
    // Add(添加项目)的
    AddVisibleS: false,
    // Particulars详情(项目列表维护的详情)
    ParShow: false,
    // PeoParticulars人员详情
    PeoParShow: false,
    // AddTasks添加任务
    AddTasksShow: false,
    // AddJNDJ添加技能等级
    AddJndjShow: false,
    // ModTasks修改任务
    ModTasksShow: false,
    // AddPeo添加人员
    AddPeoShow: false,

    // 专业
    ZhuanYeList: '',
    // ZhuanYeList:[{value:'专业一',label:'专业一',},{value:'专业二',label:'专业二',}],
    // 员工状态
    YuanGongList: [{ value: '正常', label: '正常', }, { value: '禁用', label: '禁用', }, { value: '待审核', label: '待审核', }],
    // 角色名称
    JueSeList: [{ value: 'Common', label: '普通员工' }, { value: 'Admin', label: '管理员' }, { value: 'Finance', label: '财务' }],
    /* 普通员工 Common(0,"普通员工"),财务 Finance(1,"财务"),管理员 Admin(2,"管理员"); */
    // 项目类型
    LeiXing: [{ value: 'A1', label: 'A1' }],
    // 项目状态
    ZhuangTaiList: [{ value: '进行中', label: '进行中', }, { value: '已结题', label: '已结题', }, { value: '待审核', label: '待审核', }],
    // 技能等级
    DengJiList: '',
    // DengJiList:[{value: 'p1',label: 'p1'}, {value: 'p2',label: 'p2'}, {value: 'p3',label: 'p3'}, {value: 'p4',label: 'p4'}
    // , {value: 'p5',label: 'p5'}, {value: 'p6',label: 'p6'}, {value: 'p7',label: 'p7'}, {value: 'p8',label: 'p8'}, {value: 'p9',label: 'p9'}],
    // 项目组长
    ZuZhangList: ['组长一', '组长二', '组长三', '组长四', '组长五', '组长六', '组长七', '组长八', '组长九', '组长十'],
    // 任务状态
    RWZhuangTaiList: [{ value: '在研', label: '在研' }, { value: '关闭', label: '关闭' }]
  },
  mutations: {
    changeheader (state, value) {
      state.header = value;
    }
  },
  actions: {
  },
  modules: {
  }
})

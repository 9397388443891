<template>
  <div id="app">
    <el-container class="wrap" v-if="$route.meta.keepalive">
      <el-aside>
        <div style="text-align: center;">
          <img src="./assets/logoLog.png" class="imgBox"/>
        </div>
        <el-menu
          background-color="#545C64"
          text-color="#fff"
          style="border: 0;text-align: center;"
        >
          <el-menu-item index="1" :class="{ on: curIndex == 3 }" @click="GoWage"
            >薪资维护</el-menu-item
          >

          <el-menu-item
            index="2"
            :class="{ on: curIndex == 2 }"
            @click="GoGrading"
            >技能等级维护</el-menu-item
          >
          <el-menu-item
            index="7"
            :class="{ on: curIndex == 7 }"
            @click="GoProfessionalLevel"
            >专业等级维护</el-menu-item
          >
          <el-menu-item
            index="7"
            :class="{ on: curIndex == 8 }"
            @click="GoProjectType"
            >项目类型维护</el-menu-item
          >
          <el-menu-item
            index="3"
            :class="{ on: curIndex == 5 }"
            @click="GoInformation"
            >日志信息维护</el-menu-item
          >
          <el-submenu index="4">
            <template #title>
              <span>公司</span>
            </template>
            <el-menu-item-group>
              <el-menu-item
                index="4-3"
                :class="{ on: curIndex == 6 }"
                @click="GoCompany"
                style="padding: 0;min-width:150px;font-size:12px;"
                >邀请码</el-menu-item
              >
              <el-menu-item
                index="4-1"
                :class="{ on: curIndex == 1 }"
                @click="GoStaff"
                style="padding: 0;min-width:150px;font-size:12px;"
                >人员信息维护</el-menu-item
              >
              <el-menu-item
                index="4-2"
                :class="{ on: curIndex == 4 }"
                @click="GoProjectList"
                style="padding: 0;min-width:150px;font-size:12px;"
                >项目列表维护</el-menu-item
              >
            </el-menu-item-group>
          </el-submenu>
        </el-menu>
      </el-aside>
      <el-container class="inner">
        <el-header>
          河北炎石日报系统
          <el-button
            id="GB"
            icon="el-icon-switch-button"
            @click="$router.replace('/').catch((err) => {})"
          ></el-button>
        </el-header>
        <el-main>
          <router-view @hdhead="hdhead" />
        </el-main>
      </el-container>
    </el-container>
    <router-view v-if="!$route.meta.keepalive"></router-view>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
export default {
  data () {
    return {
      header: "",
      curIndex: 3,
    };
  },
  computed: { ...mapState(['base_url']) },
  created () {
    const header = JSON.parse(localStorage.getItem("header"));
    console.log("header", header);
    this.header = header;
  },
  methods: {
    GoStaff () {
      this.curIndex = 1;
      (this.$store.state.message = [
        "序号",
        "姓名",
        "登录名",
        "密码",
        "性别",
        "电子邮箱",
        "专业",
        "技能等级",
        "手机号码",
        "公司",
        "员工状态",
        "部门",
        "角色名称",
      ]),
      this.$router.replace("/Staff").catch((err) => {});
      this.$store.state.ParShow = false;
    },
    GoGrading () {
      this.curIndex = 2;
      this.$router.replace("/Grading").catch((err) => {});
      this.$store.state.ParShow = false;
    },
    GoProfessionalLevel () {
      this.curIndex = 7;
      this.$router.replace("/ProfessionalLevel").catch((err) => {});
      this.$store.state.ParShow = false;
    },
    GoProjectType () {
      this.curIndex = 8;
      this.$router.replace("/ProjectType").catch((err) => {});
      this.$store.state.ParShow = false;
    },
    GoWage () {
      this.curIndex = 3;
      this.$router.replace("/Wage").catch((err) => {});
      this.$store.state.ParShow = false;
    },
    GoProjectList () {
      this.curIndex = 4;
      (this.$store.state.message = [
        "序号",
        "项目名称",
        "项目编号",
        "项目类型",
        "立项时间",
        "结题日期",
        "项目状态",
        "项目组长",
        "甲方联系人",
        "甲方联系方式",
        "项目所属公司",
        "员工状态",
        "包含任务数",
      ]),
      this.$router.replace("/ProjectList").catch((err) => {});
      this.$store.state.ParShow = false;
    },
    GoInformation () {
      this.curIndex = 5;
      this.$router.replace("/Information").catch((err) => {});
      this.$store.state.ParShow = false;
    },
    GoCompany () {
      this.curIndex = 6;
      this.$router.replace("/Company").catch((err) => {});
      this.$store.state.ParShow = false;
    },
    hdhead (num) {
      this.curIndex = num;
      /* localStorage.removeItem("header"); */
    },
  },
  components: {},
  mounted () {
    const _this = this;
    /* 公司所有角色 */
    /* axios({
      method: "get",
      url: `${this.base_url}ManageUsers/selectByGsIdOfRole`,
      headers: this.header,
    }).then((res) => {
      console.log("公司所有角色", res);
      var Array = res.data.result;
      var obj = {};
      var JueSeList = [];
      for (var i = 0; i < Array.length; i++) {
        obj = {
          value: res.data.result[i].id,
          label: res.data.result[i].jsmc,
        };
        JueSeList.push(obj);
        obj = {};
      }
      this.$store.state.JueSeList = JueSeList;
      // console.log(res.data.result)
    }); */
    /* 查询项目类型 */
    axios({
      method: "get",
      url: `${this.base_url}project/selectOfProjectGrade`,
      headers: this.header,
    }).then(function (res) {
      console.log("项目类型", res);
      const Array = res.data.result;
      let obj = {};
      const dengji = [];
      for (let i = 0; i < Array.length; i++) {
        obj = {
          value: res.data.result[i].id,
          label: res.data.result[i].lxmc,
        };
        dengji.push(obj);
        obj = {};
        console.log("项目类型", dengji);
      }
      _this.$store.state.LeiXing = dengji;
    });
    /* 公司所有专业 */
    axios({
      method: "get",
      url: `${this.base_url}ManageUsers/selectByGsIdOfMajor`,
      headers: this.header,
    }).then(function (res) {
      console.log("公司所有专业", res);
      const Array = res.data.result;
      let obj = {};
      const ZhuanYeList = [];
      for (let i = 0; i < Array.length; i++) {
        obj = {
          value: res.data.result[i].id,
          label: res.data.result[i].zymc,
        };
        ZhuanYeList.push(obj);
        obj = {};
        console.log(ZhuanYeList);
      }
      _this.$store.state.ZhuanYeList = ZhuanYeList;
      // console.log(res.data.result)
      // console.log(_this.$store.state.ZhuanYeList)
    });
    /* 查询该公司技能 */
    axios({
      method: "get",
      url: `${this.base_url}ManageUsers/selectByGsIdOfSkill`,
      headers: this.header,
    }).then(function (res) {
      console.log("查询该公司技能 ", res);
      const Array = res.data.result;
      let obj = {};
      const DengJiList = [];
      for (let i = 0; i < Array.length; i++) {
        obj = {
          value: res.data.result[i].id,
          label: res.data.result[i].jndjmc,
        };
        DengJiList.push(obj);
        obj = {};
        console.log(DengJiList);
      }
      _this.$store.state.DengJiList = DengJiList;
      // console.log(_this.$store.state.DengJiList)
    });
  },
};
</script>

<style lang="less">
* {
  margin: 0;
  padding: 0;
}
body,
html {
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  height: 100%;
}
.el-container {
  min-height: 100%;
}

.el-table {
  font-size: 12px !important;
}
.el-header {
  height: 60px !important;
  line-height: 60px !important;
  text-align: left;
  background: #545c64;
  font-size: 16px;
  position: relative;
}
.el-aside {
  width: 150px !important;
  background: #545c64;
  padding-top: 10px;
}
.el-main {
  padding: 30px;
  position: relative;
  padding-bottom: 50px;
}
.on {
  color: #ffd04b !important;
  background: #495057 !important;
}
#app .el-menu-item.is-active {
  color: #ffd04b;
}
/* 滚动条的宽度 */
body::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
/* 滚动条的滑块 */
body::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
}

.el-table td,
.el-table th {
  text-align: center !important;
}
/* 滚动条的宽度 */
.el-table__body-wrapper::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
/* 滚动条的滑块 */
.el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 4px;
}
/* 分页样式 */
.el-pagination {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.Tabulation td .el-button,
.TableDataT td .el-button {
  padding: 2px 5px !important;
  min-height: 25px !important;
}

#GB {
  font-size: 18px;
  width: 35px;
  height: 35px;
  padding: 0;
  min-height: 35px;
  background-color: #ffffff;
  border: 0;
  color: #000;
  position: absolute;
  right: 20px;
  top: 0;
  bottom: 0;
  margin: auto;
}
// 表头错位
body .el-table th.gutter {
  display: table-cell !important;
}
body .el-table colgroup.gutter {
  display: table-cell !important;
}

.el-dialog {
  padding-bottom: 30px;
}
// 表格padding
.el-table td,
.el-table th {
  padding: 8px 0 !important;
}
.el-table th {
  background-color: #eff4f8 !important;
}
.imgBox{
  width: 90px;
  border-radius: 10px;
}
</style>
